<template>
  <div class="register-as-wrapper">
<!--    <div class="status-bar">-->
<!--      Wegens onderhoud is MediaMasters op dinsdag 2 november vanaf 17 uur mogelijk minder goed bereikbaar. Excuses voor het ongemak.-->
<!--    </div>-->
    <!-- Title and subtitle -->
    <h1 class="auth-title register-as-title">{{ $t('REGISTER_AS.REGISTER') }}</h1>
    <p class="auth-subtitle">{{ $t('REGISTER_AS.REGISTER_TEXT') }}</p>

    <!-- User Type Tabs -->
    <div class="user-types-wrapper">
      <RegisterAsRole :icon="require('../../../../assets/icons/icn_teacher.svg')"
                      :text="$t('REGISTER_AS.TEACHER')"
                      class="user-type"
                      @click="onTypeClick(ROUTE_NAMES_AUTH.REGISTER_TEACHER)"/>
      <RegisterAsRole :icon="require('../../../../assets/icons/icn_professional.svg')"
                      :text="$t('REGISTER_AS.OTHER')"
                      :subtext="$t('REGISTER_AS.OTHER_SUBTEXT')"
                      class="user-type"
                      @click="onTypeClick(ROUTE_NAMES_AUTH.REGISTER_OTHER)"/>
    </div>

    <!-- Log in prompt -->
    <AuthPrompt :text="$t('LOGIN_PROMPT.TEXT')"
                :route-name="ROUTE_NAMES_AUTH.LOGIN"
                :link-text="$t('LOGIN_PROMPT.LINK')"
                class="auth-prompt"/>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import RegisterAsRole from '@/components/elements/auth/RegisterAsRole'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import AuthPrompt from '@/components/elements/auth/AuthPrompt'

export default {
  name: 'Register',
  components: {
    AuthPrompt,
    RegisterAsRole
  },
  setup() {
    const router = useRouter()

    function onTypeClick(routeLink) {
      // Redirect user to the correct registration page.
      router.push({ name: routeLink })
    }

    return {
      ROUTE_NAMES_AUTH,
      onTypeClick
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.status-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: fit-content;
  background-color: var(--pink_main);
  padding: rem(8) rem(12);
  text-align: center;
  color: white;
}

.register-as-title {
  margin-bottom: rem(14);
}

.user-types-wrapper {
  margin-top: rem(57);

  .user-type:not(:last-child) {
    margin-bottom: rem(24);
  }
}

.login {
  margin-top: rem(86);
}
</style>
