<template>
  <div class="option-box">
    <!-- Icon -->
    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <img :src="icon" class="option-icon" alt="Box icon">

    <div class="text-wrapper">
      <!-- Text -->
      <p class="option-text">{{ text }}</p>

      <!-- Sub-Text -->
      <p v-if="subtext" class="option-subtext">{{ subtext }}</p>
    </div>

    <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
    <img src="../../../assets/icons/icn_arrow.svg" class="arrow" alt="Box arrow">
  </div>
</template>

<script>
export default {
  name: 'RegisterAsRole',
  props: {
    text: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.option-box {
  @include hover-active-pointer();
  align-items: center;
  border-radius: rem(18);
  background-color: white;
  box-shadow: rem(5) rem(5) 0 rem(1) rgba(0, 0, 0, 0.1);
  display: flex;
  padding: rem(16) rem(20) rem(16) rem(16);
  position: relative;
}

.option-icon {
  height: rem(56);
  margin-right: rem(24);
  width: rem(56);
}

.text-wrapper {
  text-align: left;

  .option-text {
    font-size: rem(16);
    font-weight: 500;
    letter-spacing: 0;
    line-height: rem(19);
    margin-bottom: rem(4);
  }

  .option-subtext {
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
    opacity: 0.7;
  }
}

.arrow {
  @include position(absolute, $top: 50%, $right: rem(20));
  transform: translateY(-50%);
  height: rem(15);
  width: auto
}
</style>
